import React from 'react'

import Layout from '../components/layout'
import Head from '../components/head'

import Thehobbitbookdesign from './work/thehobbitbookdesign.jpg'
import Lincolnnationalparkbranding from './work/lincolnnationalparkbranding.jpg'
import Festivalofdangerousideasidentiy from './work/festivalofdangerousideasidentity.jpg'
import Abstractphotography from './work/abstractphotography.jpg'
import Helveticabooklet from './work/helveticabooklet.jpg'
import Surveillanceposter from './work/surveillanceposter.jpg'

import workStyles from '../components/work.module.scss'


const WorkPage = () => {
    return (
        <Layout>
            <Head title="Work -"/>
            <div className={workStyles.flexbox}>
            <div className={workStyles.container}>
                <img src={Festivalofdangerousideasidentiy} alt="Festival Of Dangerous Ideas Identity" className={workStyles.image}/>
                <a href="./work/festivalofdangerousideasidentity">
                    <div className={workStyles.overlay}>
                        <div className={workStyles.text}>Festival Of Dangerous Ideas Identity</div>
                    </div>
                </a>
            </div>

            <div className={workStyles.container}>
                <img src={Helveticabooklet} alt="Helvetica Booklet" className={workStyles.image}/>
                <a href="./work/helveticabooklet">
                    <div className={workStyles.overlay}>
                        <div className={workStyles.text}>Helvetica Booklet</div>
                    </div>
                </a>
            </div>

            <div className={workStyles.container}>
                <img src={Lincolnnationalparkbranding} alt="Lincoln National Park Branding" className={workStyles.image}/>
                <a href="./work/lincolnnationalparkbranding">
                    <div className={workStyles.overlay}>
                        <div className={workStyles.text}>Lincoln National Park Branding</div>
                    </div>
                </a>
            </div>
            </div>



            <div className={workStyles.flexbox}>
            <div className={workStyles.container}>
                <img src={Thehobbitbookdesign} alt="The Hobbit Book Design" className={workStyles.image}/>
                <a href="./work/thehobbitbookdesign">
                    <div className={workStyles.overlay}>
                        <div className={workStyles.text}>The Hobbit Book Design</div>
                    </div>
                </a>
            </div>

            <div className={workStyles.container}>
                <img src={Abstractphotography} alt="Abstract Photography" className={workStyles.image}/>
                <a href="./work/abstractphotography">
                    <div className={workStyles.overlay}>
                        <div className={workStyles.text}>Abstract Photography</div>
                    </div>
                </a>
            </div>


            
            </div>

        </Layout>
    )
}

export default WorkPage